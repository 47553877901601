<template>
    <div class="data-content">
        <div class="left-content">
            <div class="left-inner">
                <div class="shadow-top">
                    <div class="top-item">
                        <div class="top-left shadow-3">
                            <div class="user-info">
                                <img v-if="stepOneData.avatar" :src="stepOneData.avatar" alt="" />
                                <div class="middle-info">
                                    <span class="user-live-name">{{ stepOneData.nickname }}</span>
                                    <span class="user-live-like">0本场点赞</span>
                                </div>
                                <div class="right-btn">关注</div>
                            </div>
                        </div>
                        <div class="top-right">
                            <div class="watch-num shadow-3">{{ watchNum }}</div>
                        </div>
                    </div>
                    <div class="preview-content" v-if="showIndex">
                        <img :src="baseInfo.goods_info_patch[showIndex - 1].goods_live_patch" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div class="right-content">
<!--            <div class="right-top">-->
<!--                <div class="top-tips">直播背景墙</div>-->
<!--                <div class="upload-content">-->
<!--                    <el-upload-->
<!--                        class="img-uploader"-->
<!--                        :action="uploadCover"-->
<!--                        :headers="headersParams"-->
<!--                        :data="{ type: 1}"-->
<!--                        accept=".jpg, .jpeg, .png"-->
<!--                        :show-file-list="false"-->
<!--                        :on-success="(res, file, fileList) => handleCoverSuccess(res, file, fileList, 1)"-->
<!--                        :before-upload="(file) => beforeCoverUpload(file)"-->
<!--                    >-->
<!--                        <div class="add-box">-->
<!--                            <i class="el-icon-plus add-icon"></i>-->
<!--                        </div>-->
<!--                        <div class="img-box" v-if="baseInfo.live_background">-->
<!--                            <img :src="baseInfo.live_background" />-->
<!--                            <div class="shadow-box" @click="clearImg">删除</div>-->
<!--                        </div>-->
<!--                    </el-upload>-->
<!--                    <span class="upload-tips">（建议尺寸：1080*1920px，图片大小不得超过3MB）</span>-->
<!--                </div>-->
<!--            </div>-->
            <div class="right-bottom">
                <div class="bottom-btn">
                    <el-button type="primary" size="mini" @click="addPic">添加商品</el-button>
                </div>
                <el-table :highlight-current-row="true" :data="baseInfo.goods_info_patch" class="table-live-student" :height="baseInfo.goods_info_patch.length == 0 ? 47 : 522">
                    <el-table-column label="商品信息">
                        <template slot-scope="scope">
                            <el-select
                                v-model="scope.row.goods_student_id"
                                placeholder="请选择"
                                @change="(value) => selectGoods(scope.$index, value)"
                                class="goods-select"
                                popper-class="goods-popper"
                            >
                                <el-option
                                    v-show="isSelectProduct(item)"
                                    v-for="(item, index) in multipleSelection"
                                    :key="item.id"
                                    :label="item.goods_name"
                                    :value="item.id">
                                </el-option>
                                <el-option
                                    value="暂无数据"
                                    label="暂无数据"
                                    v-if="goods_selectList.length == multipleSelection.length"
                                    :disabled="true"
                                ></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goods_sku" label="上传贴片" align="center" width="350">
                        <template slot-scope="scope">
                            <div class="upload-content table-upload">
                                <el-upload
                                    class="img-uploader"
                                    :action="uploadCover"
                                    :headers="headersParams"
                                    :data="{ type: 1}"
                                    accept=".jpg, .jpeg, .png"
                                    :show-file-list="false"
                                    :on-success="(value) => handleGoodsImg(scope.$index, value)"
                                    :before-upload="beforeGoodsImgUpload"
                                >
                                    <div class="add-box">
                                        <i class="el-icon-plus add-icon"></i>
                                    </div>
                                    <div class="img-box img-100" v-if="scope.row.goods_live_patch">
                                        <img :src="scope.row.goods_live_patch" />
                                        <div class="shadow-box">重新上传</div>
                                    </div>
                                </el-upload>
                                <div class="img-tips">图片尺寸限制为1:1，大小不可超过3MB</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <div class="op-content">
                                <div class="op-btn" @click="previewGoods(scope.row, scope.$index)">预览</div>
                                <div class="op-btn danger" @click="delBtn(scope.$index)">删除</div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="empty-data" v-if="baseInfo.goods_info_patch.length == 0">
                    <img class="no-data" src="../../../assets/images/no_data_coupon.png" alt="" />
                    <div class="no-data-tips">暂无贴片</div>
                </div>
            </div>
            <div class="download-box">
                <img src="../../../assets/images/resource.png" alt="" />
                <span class="download-text">直播间素材</span>
                <el-button type="primary" size="medium" @click="downloadFile">下载素材</el-button>
            </div>
        </div>
    </div>
</template>

<script>

import {getLiveGoodsList} from "@/utils/apis";

export default {
    name: "liveConfig",
    props: ["stepOneData", "stepTwoData", "fileUrl"],
    data() {
        return {
            watchNum: 0,
            baseInfo: {
                live_background: "",
                goods_info_patch: [],
            },
            uploadCover: this.$api.live_sale_studentUpload,
            headersParams: {
                Authorization: localStorage.getItem("token"),
            },
            goodsList: [],
            multipleSelection: [],
            goods_selectList: [],
            showIndex: "",
            showGoods: "",
            moduleId: void 0
        };
    },
    created() {
        // console.log(this.stepTwoData.goods_info_patch)
        this.getGoodsList();
        this.moduleId = Number(localStorage.getItem('liveMarketModuleId'));
    },
    methods: {
        urlChange(url, baseUrl) {
            let urlText = url.replace(baseUrl, '')
            return urlText
        },
        getGoodsList() {
            this.goodsList = JSON.parse(localStorage.getItem('goodsList'))
            this.$nextTick(() => {
                if (this.$route.query.id) {
                    this.baseInfo = this.stepTwoData
                    this.goods_selectList = [...this.baseInfo.goods_info_patch]
                    this.baseInfo.goods_info_patch.forEach((item, index) => {
                        this.selectGoods(index, item.id);
                    });
                }
            })
            this.getScriptList()
        },
        /** 上传图片 */
        handleCoverSuccess(res) {
            if (res.code === 200) {
                this.baseInfo.live_background = res.data.url;
                this.$message.success(res.msg);
            } else {
                this.$message.error("图片上传失败，请稍后再试~");
            }
        },
        beforeCoverUpload(file) {
            const isLt3M = file.size / 1024 / 1024 < 3;
            if (!isLt3M) {
                this.$message.error("上传图片大小不能超过 3MB!");
            } else {
                const isSize = new Promise((resolve, reject) => {
                    const height = 1920;
                    const width = 1080;
                    const _URL = window.URL || window.webkitURL;
                    const img = new Image();
                    img.onload = () => {
                        const valid = img.width === width && img.height === height;
                        valid ? resolve() : reject();
                    };
                    img.src = _URL.createObjectURL(file);
                }).then(
                    () => {
                        return file;
                    },
                    () => {
                        this.$message.warning("图片尺寸限制为1080px x 1920px，大小不可超过3MB");
                        return Promise.reject();
                    }
                );
                return isSize;
            }
        },
        handleGoodsImg(index, res) {
            if (res.code === 200) {
                this.baseInfo.goods_info_patch[index].goods_live_patch = res.data.url;
                this.$message.success(res.msg);
            } else {
                this.$message.error("图片上传失败，请稍后再试~");
            }
        },
        beforeGoodsImgUpload(file) {
            const isLt3M = file.size / 1024 / 1024 < 3;
            if (!isLt3M) {
                this.$message.error("上传图片大小不能超过 3MB!");
            } else {
                const isSize = new Promise((resolve, reject) => {
                    const width = 100;
                    const height = 100;
                    const _URL = window.URL || window.webkitURL;
                    const img = new Image();
                    img.onload = () => {
                        // const valid = img.width === width && img.height === height;
                        const valid = img.width === img.height;
                        valid ? resolve() : reject();
                    };
                    img.src = _URL.createObjectURL(file);
                }).then(
                    () => {
                        return file;
                    },
                    () => {
                        this.$message.warning("图片尺寸限制为1:1，大小不可超过3MB");
                        return Promise.reject();
                    }
                );
                return isSize;
            }
        },
        clearImg() {
            this.baseInfo.live_background = "";
        },
        downloadFile() {
            let url = `${window.location.origin}/live_sale/download?type=3&id=${Number(localStorage.getItem('liveMarketModuleId'))}`;
            window.open(url, "_blank");
        },
        addPic() {
            // if (this.goods_selectList.length >= this.multipleSelection.length) {
            //     this.$message.warning('请先在直播商品管理脚本添加商品数据')
            // } else {
                let obj = {
                    goods_student_id: "",
                    goods_live_patch: "",
                };
                this.baseInfo.goods_info_patch.push(obj);
            // }
        },
        selectGoods(index, val) {
            let obj = this.multipleSelection.find((item) => item.id == val);
            this.$set(this.goods_selectList,index,obj)
        },
        isSelectProduct(item) {
            if (this.goods_selectList.indexOf(item) != -1) {
                return false;
            } else {
                return true;
            }
        },
        previewGoods(row, index) {
            if (!row.goods_student_id) {
                return this.$message.warning("请先选择商品");
            }
            if (!row.goods_live_patch) {
                return this.$message.warning("请上传贴片");
            }
            if (this.showIndex == index + 1) {
                this.showIndex = ''
            } else {
                this.showIndex = index + 1;
            }
        },
        delBtn(index) {
            if (this.showIndex == index) {
                this.showIndex = "";
            }
            this.baseInfo.goods_info_patch.splice(index, 1);
            this.goods_selectList.splice(index, 1);
        },
        confirmForm() {
            if (this.multipleSelection.length == 0) {
                return this.$message.warning("您还未添加商品，请回到选品处添加商品！");
            }
            // if (!this.baseInfo.live_background) {
            //     return this.$message.warning("请添加直播背景墙！");
            // }
            // if (this.goods_selectList.length == 0) {
            //     return this.$message.warning("请添加商品！");
            // }
            for (const item of this.baseInfo.goods_info_patch) {
                if (item.goods_student_id == '') {
                    return this.$message.warning("请选择对应商品");
                }
                if (item.goods_live_patch == '') {
                    return this.$message.warning("请上传对应的贴片");
                }
            }
            this.$emit("stepActiveAdd", this.baseInfo);
            localStorage.setItem('baseInfo', JSON.stringify(this.baseInfo))
        },
        getScriptList() {
            this.$http.axiosGet(this.$api.getScriptInfo, (res) => {
                if (res.code == 200) {
                    let arr = res.data.list.map((item) => {
                        if (!!item.goods_id) {
                            return item.goods_id
                        }
                    }).filter(l => l != undefined);
                    this.arr = new Set(arr)
                    this.multipleSelection = this.goodsList.map((item) => {
                        if (arr.includes(item.id)) {
                            return item
                        }
                    }).filter(l => l != undefined);
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
/deep/.tables tbody tr:hover >td  {
    background-color: unset !important;
}
/deep/.el-table.table-live-student {
    color: #455A94;
    background-color: #F8F9FB;
    font-weight: 500;
    th {
        color: #8CA5C6;
        background-color: #F2F3FA!important;
    }
    .el-table__row {
        height: 60px;
        border: 0px;
    }
    .el-link {
        margin-right: 24px;
        &:last-child {
            margin-right: 0;
        }
    }
    .el-table__body-wrapper {
        background-color: #ffffff;
    }
    tr.el-table__row:last-child .el-table__cell {
        border: none;
    }
    tr.el-table__row:last-child .el-table__cell {
        border: none;
    }
    td.el-table__cell:last-child {
        border: none;
    }
}
.data-content {
    display: flex;
    height: 100%;
    box-sizing: content-box;

    .left-content {
        //height: 860px;
        //width: 375px;
        height: 795px;
        width: 430px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 20px;
        background-image: url("../../../assets/images/live_background.png");
        background-repeat: no-repeat;
        background-size: cover;
    }
    .right-content {
        flex: 1;
        margin-left: 20px;
        overflow: hidden;

        .top-tips {
            width: 80px;
            //color: #fff;
            position: relative;

            &:before {
                //content: "*";
                //color: #ff3e6c;
                //margin-right: 4px;
                //position: absolute;
                //right: -10px;
                //top: 2px;
            }
        }
        .right-bottom {
            //margin-top: 30px;
            //min-height: 480px;
            height: 100%;
            .bottom-btn {
                text-align: right;
                margin-bottom: 13px;
            }
        }
    }
}

.left-inner {
    padding: 20px;
    height: calc(100% - 40px);
}

.top-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shadow-3 {
    background: rgba(0, 0, 0, 0.3);
}

.shadow-5 {
    background: rgba(0, 0, 0, 0.5);
}

.top-left {
    width: 202px;
    height: 44px;
    border-radius: 22px;
}

.user-info {
    height: 37px;
    padding: 2px;
    display: flex;
    justify-content: space-between;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .middle-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        color: #fff;
        margin: 4px 0 0 7px;

        .user-live-name {
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .user-live-like {
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .right-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px;
        width: 48px;
        height: 36px;
        background-color: #fe3266;
        border-radius: 18px;
        cursor: pointer;
        font-size: 15px;
        font-weight: bold;
        color: #fff;
    }

    .grey-btn {
        background: rgba(0, 0, 0, 0.3);
    }
}

.top-right {
    height: 44px;
    display: flex;
    align-items: center;

    .watch-num {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 13px;
        width: 46px;
        height: 28px;
        color: #fff;
        margin-right: 10px;
    }

    .close-content {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        i {
            font-size: 17px;
            color: #fff;
        }
    }
}

.tag-content {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;

    .tag-item {
        color: #fff;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px;
        margin-right: 9px;
    }
}

.preview-content {
    margin-top: 12px;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    img {
        width: 30%;
        height: auto;
    }
}

.upload-content {
    display: flex;
    margin-top: 12px;
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    justify-content: flex-start;

    &.table-upload {
        justify-content: center;

        .img-uploader {
            width: 100px;
            height: 100px;
        }
    }

    .upload-tips {
        display: flex;
        align-items: center;
        margin: 0 96px 0 20px;
    }

    .img-box {
        width: 108px;
        height: 192px;
        position: relative;

        &.img-100 {
            width: 100px;
            height: 100px;

            img {
                width: 100px;
                height: 100px;
            }
        }

        .shadow-box {
            position: absolute;
            bottom: 0;
            background: #000000;
            opacity: 0.6;
            border-radius: 4px;
            width: 100%;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 12px;
            cursor: pointer;
        }

        img {
            width: 108px;
            height: 192px;
        }
    }

    .img-uploader {
        width: 108px;
        height: 192px;
        //background: #0c0e3f;
        border: 1px dashed #DCDFE6;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: relative;
        border-radius: 4px;

        .add-box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            line-height: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .add-icon {
                color: #DCDFE6;
                font-size: 24px;
            }
        }
    }

    .img-tips {
        position: absolute;
        height: 20px;
        left: 60px;
        //color: #ffffff;
        bottom: -30px;
    }
}

.main-table {
    //background: #070932;
}

.el-table {
    width: 100%;

    &:before {
        content: none;
    }

    ::v-deep .el-table__empty-block {
        display: none;
    }

    ::v-deep th.el-table__cell.is-leaf {
        border-bottom: none;
    }

    ::v-deep th.el-table__cell {
        &:first-child {
            & > .cell {
                padding-left: 70px;
            }
        }

        &.is-leaf {
            border-bottom: none;
        }
    }

    ::v-deep td.el-table__cell {
        &:first-child {
            & > .cell {
                padding-left: 70px;
            }
        }
    }

    ::v-deep .cell {
        overflow: unset;
    }
}

.download-box {
    display: flex;
    margin-top: 20px;
    align-items: center;

    img {
        width: 26px;
        height: 26px;
    }

    .download-text {
        margin: 0 20px 0 10px;
    }
}

.empty-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;

    .no-data {
        width: 322px;
        height: 271px;
    }

    .no-data-tips {
        margin: 58px 0 34px;
        color: #fff;
    }
}

.goods-select {
    width: 300px;

    ::v-deep .el-icon-arrow-up:before {
        content: "\e78f";
    }
}

.op-content {
    display: flex;
    justify-content: center;

    .op-btn + .op-btn {
        margin-left: 20px;
    }

    .op-btn {
        width: 76px;
        height: 30px;
        background: rgba(75, 69, 255, 0.1);
        border: 1px solid #4b45ff;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #4b45ff;
        cursor: pointer;

        &.danger {
            border: 1px solid #ff3e6c;
            background: rgba(255, 62, 108, 0.1);
            color: #ff3e6c;
        }
    }
}
</style>
