<template>
    <div class="data-content">
        <el-form :model="baseInfo" :rules="rules" ref="ruleForm" label-width="100px" class="live-ruleForm" label-position="top">
            <el-form-item label="直播间标题" prop="title">
                <el-input class="dark-input" placeholder="请输入直播间标题（4-17）" v-model="baseInfo.title"></el-input>
            </el-form-item>
            <el-form-item label="直播间简介" prop="description">
                <el-input
                    type="textarea"
                    class="dark-text"
                    :rows="3"
                    placeholder="请输入直播间简介（8-500）"
                    v-model="baseInfo.description"
                ></el-input>
            </el-form-item>
            <el-form-item label="主播昵称" prop="nickname">
                <el-input class="dark-input" placeholder="请输入主播昵称（5-15）" v-model="baseInfo.nickname"></el-input>
            </el-form-item>
            <el-form-item label="头像" class="upload-wrapper">
                <div class="upload-content">
                    <div class="img-box" v-if="baseInfo.avatar">
                        <img :src="baseInfo.avatar" />
                        <div class="shadow-box" @click="clearImg(1)">删除</div>
                    </div>
                    <el-upload
                        v-else
                        class="img-uploader"
                        :action="uploadCover"
                        :headers="headersParams"
                        :data="{ type: 1}"
                        accept=".jpg, .jpeg, .png"
                        :show-file-list="false"
                        :on-success="(res, file, fileList) => handleAvatarSuccess(res, file, fileList, 1)"
                        :before-upload="(file) => beforeAvatarUpload(file)"
                    >
                        <div class="add-box">
                            <i class="el-icon-plus add-icon"></i>
                        </div>
                    </el-upload>
                    <span class="upload-tips">（建议尺寸比例1:1）</span>
                </div>
            </el-form-item>
            <el-form-item label="直播封面  " class="upload-wrapper">
                <div class="upload-content">
                    <div class="img-box" v-if="baseInfo.cover">
                        <img :src="baseInfo.cover" />
                        <div class="shadow-box" @click="clearImg(2)">删除</div>
                    </div>
                    <el-upload
                        v-else
                        class="img-uploader"
                        :action="uploadCover"
                        :headers="headersParams"
                        :data="{ type: 1}"
                        accept=".jpg, .jpeg, .png"
                        :show-file-list="false"
                        :on-success="(res, file, fileList) => handleCoverSuccess(res, file, fileList, 1)"
                        :before-upload="(file) => beforeCoverUpload(file)"
                    >
                        <div class="add-box">
                            <i class="el-icon-plus add-icon"></i>
                        </div>
                    </el-upload>
                    <span class="upload-tips">（建议尺寸：800*800px，图片大小不得超过3MB）</span>
                </div>
            </el-form-item>
        </el-form>
        <div class="show-content">
            <div class="top-tips">预览效果</div>
            <div class="main-content">
                <div class="top-imgBox">
                    <img :src="baseInfo.cover ? baseInfo.cover : require('../../../assets/images/live_manage.png')" alt="" />
                    <div class="footer-content">
                        <i class="el-icon-user-solid icon"></i>
                        <div>0</div>
                    </div>
                </div>
                <div class="bottom-box">
                    <div class="bottom-title">{{ baseInfo.title ? baseInfo.title : "直播间标题" }}</div>
                    <div class="bottom-imgBox">
                        <img :src="baseInfo.avatar ? baseInfo.avatar : require('../../../assets/images/blank_headPic.png')" alt="" />
                        <div class="bottom-name">{{ baseInfo.nickname ? baseInfo.nickname : "主播昵称" }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "liveBaseInfo",
    props: ["stepOneData"],
    data() {
        return {
            baseInfo: {
                title: "",
                nickname: "",
                description: "",
                avatar: "",
                cover: "",
                base_url: ''
            },
            rules: {
                title: [
                    { required: true, message: "请输入直播间标题", trigger: "blur" },
                    { min: 4, max: 17, message: "长度在 4 到 17 个字符", trigger: "blur" },
                ],
                nickname: [
                    { required: true, message: "请输入主播昵称", trigger: "blur" },
                    { min: 5, max: 15, message: "长度在 5 到 15 个字符", trigger: "blur" },
                ],
                description: [
                    { required: true, message: "请输入直播间简介", trigger: "blur" },
                    { min: 8, max: 500, message: "长度在 8 到 500 个字符", trigger: "blur" },
                ]
            },
            uploadCover: this.$api.live_sale_studentUpload,
            headersParams: {
                Authorization: localStorage.getItem("token"),
            },
        };
    },
    mounted() {
        if (this.$route.query.id) {
            this.baseInfo = {...this.stepOneData}
        }
    },
    watch: {
        stepOneData: function (d) {
            this.baseInfo = d;
        }
    },
    methods: {
        urlChange(url, baseUrl) {
            let urlText = url.replace(baseUrl, '')
            return urlText
        },
        handleAvatarSuccess(res, file) {
            if (res.code === 200) {
                this.baseInfo.avatar = res.data.url;
                this.$message.success(res.msg);
            } else {
                this.$message.error("图片上传失败，请稍后再试~");
            }
        },
        beforeAvatarUpload(file) {
            const isSize = new Promise((resolve, reject) => {
                const _URL = window.URL || window.webkitURL;
                const img = new Image();
                img.onload = () => {
                    const valid = img.width / img.height === 1;
                    valid ? resolve() : reject();
                };
                img.src = _URL.createObjectURL(file);
            }).then(
                () => {
                    return file;
                },
                () => {
                    this.$message.warning("图片尺寸比例限制为1:1");
                    return Promise.reject();
                }
            );
            return isSize;
        },
        handleCoverSuccess(res) {
            if (res.code === 200) {
                this.baseInfo.cover = res.data.url;
                this.$message.success(res.msg);
            } else {
                this.$message.error("图片上传失败，请稍后再试~");
            }
        },
        beforeCoverUpload(file) {
            const isLt3M = file.size / 1024 / 1024 < 3;
            if (!isLt3M) {
                this.$message.error("上传图片大小不能超过 3MB!");
            } else {
                const isSize = new Promise((resolve, reject) => {
                    const width = 800;
                    const height = 800;
                    const _URL = window.URL || window.webkitURL;
                    const img = new Image();
                    img.onload = () => {
                        const valid = img.width === width && img.height === height;
                        valid ? resolve() : reject();
                    };
                    img.src = _URL.createObjectURL(file);
                }).then(
                    () => {
                        return file;
                    },
                    () => {
                        this.$message.warning("图片尺寸限制为800px x 800px，大小不可超过3MB");
                        return Promise.reject();
                    }
                );
                return isSize;
            }
        },
        clearImg(type) {
            if (type == 1) {
                this.baseInfo.avatar = "";
            } else {
                this.baseInfo.cover = "";
            }
        },
        confirmForm() {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    // if (this.baseInfo.avatar == '') {
                    //     this.$message.warning('请上传头像！')
                    //     return
                    // }
                    // if (this.baseInfo.cover == '') {
                    //     this.$message.warning('请上传直播封面！')
                    //     return
                    // }
                    this.$emit("stepActiveAdd", this.baseInfo);
                    localStorage.setItem("liveBaseInfo", JSON.stringify(this.baseInfo));
                } else {
                    return false;
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.data-content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .show-content {
        flex: 1;
        display: flex;
        flex-direction: row;
        //justify-content: flex-end;
        justify-content: center;

        .top-tips {
            margin-right: 20px;
        }

        .main-content {
            width: 400px;
            height: 460px;
            background: #0c0e3f;
            border-radius: 20px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            //flex: 1;

            .top-imgBox {
                width: 400px;
                height: 330px;
                position: relative;
                margin-left: -30px;
                margin-top: -30px;
                position: relative;
                margin-right: 100px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    //margin-left: -3px;
                }

                .footer-content {
                    position: absolute;
                    width: 400px;
                    bottom: 0;
                    background: #000000;
                    opacity: 0.6;
                    display: flex;
                    align-items: center;
                    padding: 25px;
                    color: #fff;
                    font-size: 16px;

                    i {
                        font-size: 24px;
                        margin-right: 13px;
                    }
                }
            }

            .bottom-box {
                padding: 20px;
                color: #fff;
                flex: 1;

                .bottom-title {
                    font-size: 18px;
                }

                .bottom-imgBox {
                    margin-top: 13px;
                    display: flex;
                    align-items: center;

                    img {
                        width: 54px;
                        height: 54px;
                        border-radius: 50%;
                    }

                    .bottom-name {
                        margin-left: 10px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.el-form {
    ::v-deep .el-form-item {

        &.is-required {
            .el-form-item__label {
                position: relative;

                &::before {
                    position: absolute;
                    right: -15px;
                }
            }
        }
    }
}

.el-radio {
    display: flex;
    line-height: 16px;

    &.is-checked {
        ::v-deep .el-radio__inner {
            border: 3px solid #4b45ff;

            &::after {
                content: unset;
            }
        }

        ::v-deep .el-radio__label {
            color: #fff;
        }
    }
}

.el-radio + .el-radio {
    margin-top: 20px;
}

.label-tips {
    margin-left: 10px;
    opacity: 0.2;
    color: #fff;
}

.dark-input {
    width: 380px;
}

.dark-text {
    width: 800px;
}

.upload-content {
    display: flex;

    .upload-tips {
        //color: #fff;
        opacity: 0.2;
        display: flex;
        align-items: center;
        margin: 0 96px 0 20px;
    }

    .img-box {
        width: 100px;
        height: 100px;
        position: relative;

        .shadow-box {
            position: absolute;
            bottom: 0;
            background: #000000;
            opacity: 0.6;
            border-radius: 4px;
            width: 100px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 12px;
            cursor: pointer;
        }

        img {
            width: 100px;
            height: 100px;
        }
    }

    .img-uploader {
        width: 100px;
        height: 100px;
        border: 1px solid #DCDFE6;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: relative;
        border-radius: 4px;

        .add-box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            line-height: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .add-icon {
                color: #DCDFE6;
                font-size: 24px;
            }
        }
    }
}
</style>
