<template>
    <div class="live-content">
        <el-scrollbar>
            <el-breadcrumb class="live-breadcrumb" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>直播间管理</el-breadcrumb-item>
                <el-breadcrumb-item>创建直播间</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="live-title">创建直播间</div>
            <div class="steps-wrapper">
                <div class="step-item" :class="{current: [1,2].includes(stepActive)}">
                    <div class="step-line"></div>
                    <div class="step-text">01</div>
                    <div class="step-title">基本信息</div>
                </div>
                <div class="step-item" :class="{current: [2].includes(stepActive)}">
                    <div class="step-line"></div>
                    <div class="step-text">02</div>
                    <div class="step-title">直播间配置</div>
                </div>
            </div>
            <div class="form-content">
                <liveBaseInfo ref="liveBaseInfo" v-show="stepActive === 1" @stepActiveAdd="stepActiveAdd"
                              :stepOneData="stepOneData"></liveBaseInfo>
                <liveConfig ref="liveConfig" v-show="stepActive === 2" :stepOneData="stepOneData"
                            :stepTwoData="stepTwoData" :fileUrl="fileUrl"
                            @stepActiveAdd="stepActiveAdd"></liveConfig>
            </div>
            <div class="footer-content">
                <el-button  v-if="stepActive === 1" @click="toBack">取消</el-button>
                <el-button type="primary" v-if="stepActive === 1" @click="nextStep">下一步</el-button>
                <el-button type="primary" v-if="stepActive === 2" @click="lastStep">上一步</el-button>
                <el-button type="primary" v-if="stepActive === 2" @click="createLive">创建</el-button>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import liveBaseInfo from "../../../components/student/liveManage/liveBaseInfo";
import liveConfig from "../../../components/student/liveManage/liveConfig";
import {getRoomInfo, postOperateRoom, postRoomList} from "@/utils/apis";

export default {
    name: "liveRoomManageCreateLive",
    components: {
        liveConfig,
        liveBaseInfo
    },
    data() {
        return {
            stepActive: 1,
            stepOneData: {
                avatar: '',
                base_url: '',
                cover: '',
                description: '',
                goods_info_patch: [],
                id: void 0,
                live_background: '',
                nickname: '',
                title: '',
            },
            stepTwoData: {
                live_background: '',
                goods_info_patch: []
            },
            fileUrl: 'http://match.e-class.me/guo/room/download',
        }
    },
    created() {
        if (this.$route.query.id) {
            this.getRoomInfoFn(this.$route.query.id)
        }
    },
    methods: {
        getRoomInfoFn(id) {
            let params = {
                id
            }
            getRoomInfo(params).then(res => {
                if (res.code == 200) {
                    this.stepOneData = res.data
                    this.stepOneData.avatar = this.urlChange(this.stepOneData.avatar, this.stepOneData.base_url)
                    this.stepOneData.cover = this.urlChange(this.stepOneData.cover, this.stepOneData.base_url)
                    this.stepTwoData.live_background = this.urlChange(res.data.live_background, this.stepOneData.base_url)
                    this.stepTwoData.goods_info_patch = [...res.data.goods_info_patch]
                    this.stepTwoData.goods_info_patch.forEach((item) => {
                        let obj = item
                        obj.goods_live_patch = this.urlChange(obj.goods_live_patch, this.stepOneData.base_url)
                    })
                }
            })
        },
        urlChange(url, baseUrl) {
            let urlText = url.replace(baseUrl, '')
            return urlText
        },
        toBack() {
            this.$router.push('/student/liveStreamingSales/roomsManagement')
        },
        nextStep() {
            this.$refs.liveBaseInfo.confirmForm()
        },
        lastStep() {
            this.stepActive--
        },
        createLive() {
            this.$refs.liveConfig.confirmForm()
        },
        stepActiveAdd(val) {
            if (this.stepActive === 1) {
                this.stepOneData = val;
                this.stepActive++
            } else {
                this.stepTwoData = val
                this.mainConfirm()
            }
        },
        mainConfirm() {
            let params = Object.assign(this.stepOneData, this.stepTwoData)
            delete params.base_url
            if (this.$route.query.id) {
                params.id = this.$route.query.id
            } else {
                params.id = 0
            }
            postOperateRoom(params).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.msg)
                    this.toBack()
                } else {
                    this.$message.warning(res.msg)
                }
            })
        },
    }
}
</script>

<style scoped lang="scss">
.live-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    .el-scrollbar {
        margin: 20px;
        height: calc(100% - 40px);
    }

    ::v-deep .el-scrollbar__wrap {
        height: 100%;
        overflow-x: hidden;

        .el-scrollbar__view {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    .live-title {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        color: #333;
    }
}

.live-breadcrumb {
    ::v-deep.el-breadcrumb__item {

        &:last-child .el-breadcrumb__inner {
            color: #ABABBB;
        }

        .el-breadcrumb__inner {
            color: #5C5C6F;
        }

        .el-breadcrumb__separator {
            color: #5C5C6F;
        }
    }
}

.steps-wrapper {
    margin: 30px 0 0;

    ::v-deep .step-item {

        .step-title {
            color: #333;
            opacity: 0.2;
        }

        .step-line {
            //left: -200px
        }

        .step-text {
            background: #262963;
            opacity: 0.5;
        }

        &.current {
            .step-text {
                background: #2821FC;
                opacity: 1;
            }

            .step-title {
                opacity: 1;
            }
        }
    }
}

.form-content {
    /*flex: 1;*/
    /*height: 1%;*/
    width: 100%;
    margin-top: 20px;
    box-sizing: content-box;
}

.footer-content {
    text-align: center;
    margin: 20px 0;

    .fade-btn {
        background: #0C0E3F;
        border: 1px solid #262963;
        color: #fff;
    }
}
</style>